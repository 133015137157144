import { subject } from '@casl/ability'
import { HStack, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiUserSwitch, PiTrash } from 'react-icons/pi'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AccountDeleteButton from '@app/pages/settings/organization/components/accountDeleteButton'
import AccountSwitchButton from '@app/pages/settings/user/components/accountSwitchButton'
import Can from '@app/shared/authorization/can'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
}

const AccountsListActions: FC<Props> = ({ account }) => {
  const { user } = useStoreCurrentUser()
  const { id } = account

  return (
    <Can I="delete" this={subject('account', { id, organizationId: user?.organization?.id })}>
      <HStack spacing={0}>
        <Spacer />
        <AccountSwitchButton account={account}>
          <Tooltip label="Switch to this account">
            <IconButton
              color="gray.500"
              aria-label="Switch to this account"
              icon={<PiUserSwitch />}
              isDisabled={!!account.disabledAt}
              variant="ghost"
            />
          </Tooltip>
        </AccountSwitchButton>
        <AccountDeleteButton account={account}>
          <Tooltip label="Delete account">
            <IconButton color="gray.500" aria-label="Delete accoount" icon={<PiTrash />} variant="ghost" />
          </Tooltip>
        </AccountDeleteButton>
      </HStack>
    </Can>
  )
}

export default AccountsListActions
