import { Avatar, HStack, Icon, Text, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'

import IconMap from '@app/utils/iconMap'
import profileImage from '@app/utils/profileImage'
import type { Metric, User } from '@graphql/types'

interface Props {
  metric: Metric
  owner: User
}

const GoalMetricHeader: FC<Props> = ({ metric, owner }) => (
  <HStack>
    <Icon as={IconMap.Metric} boxSize={4} />
    <Text fontWeight="semibold">{metric.name}</Text>
    <Spacer />
    {owner && (
      <>
        <Avatar name={owner.name} size="xs" src={profileImage(owner.email)} />
        <Text>{owner.name}</Text>
      </>
    )}
  </HStack>
)

export default GoalMetricHeader
