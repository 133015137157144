import { Link as ChakraLink, Button, Hide, HStack, Spacer, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import WordmarkColor from '@app/images/wordmarkColor'

interface Props {
  showPlaybooksCta?: boolean
}

const Header: FC<Props> = ({ showPlaybooksCta = true }) => (
  <HStack>
    <Hide below="lg">
      <ChakraLink href="https://doubleloop.app">
        <WordmarkColor w="200px" h="auto" color="fg.default" />
      </ChakraLink>
      <Spacer />
    </Hide>
    {showPlaybooksCta && (
      <>
        <Text fontSize="sm">What are we missing?</Text>
        <Button as={ChakraLink} href="https://doubleloop.canny.io/playbook-suggestions" isExternal variant="primary">
          Request a playbook.
        </Button>
      </>
    )}
  </HStack>
)

export default Header
