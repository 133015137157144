import { Center } from '@chakra-ui/react'

import AccountsList from '@app/pages/settings/organization/components/accountsList'
import InvitationForm from '@app/pages/settings/organization/components/invitationForm'
import InvitationsList from '@app/pages/settings/organization/components/invitationsList'
import Can from '@app/shared/authorization/can'
import Card from '@app/shared/layout/card'
import CardHeader from '@app/shared/layout/cardHeader'
import PageStack from '@app/shared/layout/pageStack'

const AccountsAndInvites = () => (
  <Center>
    <PageStack>
      <Can I="create" an="invitation">
        <InvitationForm />
      </Can>
      <Card isStack maxW="3xl">
        <CardHeader title="Accounts" subtitle="Manage who can access the information in DoubleLoop" />
        <AccountsList />
      </Card>
      <Card isStack maxW="3xl">
        <CardHeader title="Invitations" />
        <InvitationsList />
      </Card>
    </PageStack>
  </Center>
)

export default AccountsAndInvites
