import { Box, Button, Center, HStack, Text } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const MultiAccountBanner: FC<BoxProps> = ({ ...rest }) => {
  const { user } = useStoreCurrentUser()

  if (!user || user.accountsCount < 2) {
    return null
  }

  return (
    <Box p={2} bgGradient="linear(to-r, #7928CA, #9B447E)" {...rest}>
      <Center>
        <HStack spacing={3}>
          <Text color="fg.accent.default" fontSize="sm" fontWeight="semibold">
            Logged into {user?.organization?.name}
          </Text>
          <Button as={Link} color="white" colorScheme="whiteAlpha" size="2xs" to="/settings/user/edit">
            Switch accounts
          </Button>
        </HStack>
      </Center>
    </Box>
  )
}

export default MultiAccountBanner
