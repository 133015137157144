import { Flex, useMultiStyleConfig } from '@chakra-ui/react'
import { ReactFlowProvider } from '@xyflow/react'
import type { FC } from 'react'
import { useRef, Suspense } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'

import PermissionsContextProvider from './permissionsContext'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import BackgroundChangeLoader from '@app/pages/maps/components/backgroundChangeLoader'
import Nav from '@app/pages/maps/components/map/nav'
import DrawerContextProvider from '@app/pages/maps/drawerContext'
import { MapShell } from '@app/shared/layout'
import MultiAccountBanner from '@app/shared/multiAccountBanner'
import type { StrategyQueryStrategy } from '@app/types'

interface Props {
  embedded?: boolean
}

const Show: FC<Props> = ({ embedded = false }) => {
  const { user } = useStoreCurrentUser()

  const { strategy } = useLoaderData() as {
    strategy: StrategyQueryStrategy
  }

  const cursorPanel = useRef(null)
  const styles = useMultiStyleConfig('MapShell')

  return (
    <Suspense fallback={<BackgroundChangeLoader />}>
      <ReactFlowProvider>
        <DrawerContextProvider strategyId={strategy.id}>
          <PermissionsContextProvider strategy={strategy} user={user}>
            <MapShell strategyId={strategy.id}>
              <MultiAccountBanner />
              <Nav embedded={embedded} strategyId={strategy.id} />
              <Flex ref={cursorPanel} sx={{ ...styles.mainContainer }} pos="relative">
                <Outlet />
              </Flex>
            </MapShell>
          </PermissionsContextProvider>
        </DrawerContextProvider>
      </ReactFlowProvider>
    </Suspense>
  )
}

Show.displayName = 'Show'

export default Show
