import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { RoleEnum } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const RoleCell: FC<Props> = ({ data: account }) => {
  const { user } = useStoreCurrentUser()
  const { role, disabledAt, organizationId } = account

  return (
    <Text
      color={disabledAt ? 'fg.subtle' : ''}
      fontSize="sm"
      fontWeight={user?.organization?.id === organizationId ? 'semibold' : ''}
    >
      {role === RoleEnum.Viewer ? 'Viewer' : 'Editor'}
    </Text>
  )
}

export default RoleCell
