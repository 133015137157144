import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  title: string
  value: string | number
}

const GoalStat: FC<Props> = ({ title, value }) => (
  <Stack spacing={0}>
    <Text color="fg.subtle" fontSize="sm" fontWeight="semibold">
      {title}
    </Text>
    <Text fontSize="md" fontWeight="bold">
      {value}
    </Text>
  </Stack>
)

export default GoalStat
